<template>
  <div>
    <div v-if="appType === 'parent'" style="margin-top: 5px">
      <div class="button-click-left">
        <!-- chọn khối -->
        <el-select
            class="button-left-class"
            clearable
            v-model="dataSearchStudent.idGrade"
            placeholder="Chọn khối"
            @change="changeGradeMethod"
        >
          <el-option
              v-for="item in gradeOfSchoolList"
              :key="item.id"
              :value="item.id"
              :label="item.gradeName"
          ></el-option>
        </el-select>
        <!-- chọn lớp -->
        <el-select
            class="button-left-class"
            filterable
            clearable
            v-model="dataSearchStudent.idClass"
            placeholder="Chọn lớp"
        >
          <el-option
              v-for="item in classList"
              :key="item.id"
              :value="item.id"
              :label="item.className"
          ></el-option>
        </el-select>
      </div>

    </div>
    <div v-else></div>

    <div>
      <el-tabs type="card" v-model="activeName" @tab-click="handleClickTab">
        <!-- tab icon Parent -->
        <el-tab-pane label="App Onekids" name="appOnekids">
          <!-- table 1 -->
          <el-table
              class="table-icon-1"
              border
              :data="parentIconAppUpdate.parentIconUpdate1"
              highlight-current-row
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeParent1"
          >
            <el-table-column
                type="selection"
                width="55"
                align="center"
            ></el-table-column>
            <el-table-column type="index" label="STT" align="center" width="50"></el-table-column>
            <el-table-column
                prop="iconName"
                label="Chức năng"
            ></el-table-column>
            <el-table-column label="Hiển thị" align="center" width="80">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.statusShow"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Mở khóa" align="center" width="80">
              <template slot-scope="scope">
                <el-checkbox
                    v-if="scope.row.rootLockStatus"
                    v-model="scope.row.status"
                ></el-checkbox>
                <el-checkbox v-else disabled></el-checkbox>
              </template>
            </el-table-column>
          </el-table>

          <!-- table 2 -->
          <el-table
              class="table-icon-2"
              border
              :data="parentIconAppUpdate.parentIconUpdate2"
              highlight-current-row
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeParent2"
          >
            <el-table-column
                type="selection"
                width="55"
                align="center"
            ></el-table-column>
            <el-table-column type="index" label="STT" align="center" width="50"></el-table-column>
            <el-table-column
                prop="iconName"
                label="Chức năng"
            ></el-table-column>
            <el-table-column label="Hiển thị" align="center" width="80">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.statusShow"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Mở khóa" align="center" width="80">
              <template slot-scope="scope">
                <el-checkbox

                    v-if="scope.row.rootLockStatus"
                    v-model="scope.row.status"
                ></el-checkbox>
                <el-checkbox v-else disabled></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <!-- tab icon Teacher -->
        <el-tab-pane label="App Teacher" name="appTeacher">
          <!-- table 1 -->
          <el-table
              class="table-icon-1"
              border
              :data="teacherIconAppUpdate.teacherIconUpdate1"
              highlight-current-row
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeTeacher1"

          >
            <el-table-column
                type="selection"
                width="55"
                align="center"
            ></el-table-column>
            <el-table-column type="index" label="STT" align="center" ></el-table-column>
            <el-table-column prop="iconName" label="Chức năng"></el-table-column>
            <el-table-column label="Hiển thị" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.statusShow"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Mở khóa" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    v-if="scope.row.rootLockStatus"
                    v-model="scope.row.status"
                ></el-checkbox>
                <el-checkbox v-else disabled></el-checkbox>
              </template>
            </el-table-column>
          </el-table>

          <!-- table 2 -->
          <el-table
              class="table-icon-2"
              border
              :data="teacherIconAppUpdate.teacherIconUpdate2"
              highlight-current-row
              :header-cell-style="tableHeaderColor"
              @selection-change="handleSelectionChangeTeacher2"

          >
            <el-table-column
                type="selection"
                width="55"
                align="center"
            ></el-table-column>
            <el-table-column type="index" label="STT" align="center"></el-table-column>
            <el-table-column prop="iconName" label="Chức năng"></el-table-column>
            <el-table-column label="Hiển thị" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.statusShow"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Mở khóa" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    v-if="scope.row.rootLockStatus"
                    v-model="scope.row.status"
                ></el-checkbox>
                <el-checkbox v-else disabled></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>


    <!-- buton click -->
    <div class="tab-infor-button">
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm()"
      >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
    </div>

  </div>
</template>

<script>
import StudentService from "@/services/StudentService";
import EmployeeDataService from "@/services/EmployeeService/EmployeeDataService";
import {mapState} from "vuex";

export default {
  components: {},
  props: {
    dialogVisible: null,
  },
  created() {

    this.$store.dispatch('gradeModule/fetchDataGradeOfSchoolList');
    this.$store.dispatch('classStore/fetchDataClassListCommon');
    this.getDataInitial()

  },
  computed: {
    ...mapState('gradeModule', ['gradeOfSchoolList']),
    ...mapState('classStore', ['classListCommon']),
    classList() {
      return this.classListCommon.filter((x) => x.idGrade === this.dataSearchStudent.idGrade);
    },
  },
  data() {
    return {
      activeName: "appOnekids",
      appType: "parent",
      classOfGradeList: [],
      parentIconAppUpdate: {
        parentIconUpdate1: [],
        parentIconUpdate2: [],
      },
      formAllTabStudent: {
        parentIconApp: [],
      },
      formAllTabTeacher: {
        teacherIconApp: [],
      },
      value: "",
      loadingButton: false,
      teacherIconAppUpdate: {
        teacherIconUpdate1: [],
        teacherIconUpdate2: [],
      },

      dataSearchStudent: {
        idGrade: null,
        idClass: null,
      },
      idDepartment: null,
      multipleSelectionParent1: [],
      multipleSelectionParent2: [],
      multipleSelectionTeacher1: [],
      multipleSelectionTeacher2: [],
    };
  },
  methods: {
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    //reset when click x
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.clearTabDefault();
      }, 300);
    },
    closeDialogByButton(fromData) {
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");


      setTimeout(() => {
        this.clearTabDefault();
      }, 300);
    },
    handleClickTab(tab) {
      if (tab.name == "appOnekids") {
        this.appType = "parent"
        this.tabParentIconMethod();
      } else {
        this.appType = "teacher"
        this.tabTeacherIconMethod();
      }
    },
    clearTabDefault() {
      this.activeName = "appOnekids";
    },
    submitForm() {
      this.loadingButton = true;
      if (this.appType === 'parent') {
        let iconArray = this.multipleSelectionParent1.concat(this.multipleSelectionParent2)
        if (iconArray.length === 0) {
          this.$message({
            message: "Không có Icon App Parent nào được chọn",
            type: "error",
          });
          this.loadingButton = false;
        } else {
          let dataUpdateParent = {
            idGrade: this.dataSearchStudent.idGrade,
            idClass: this.dataSearchStudent.idClass,
            parentIconAppList: iconArray
          }
          StudentService.updateAppIconParentForManyStudent(dataUpdateParent)
              .then((resp) => {
                this.$message({
                  message: resp.data.message,
                  type: resp.data.data === true ? "success" : "error",
                });
                setTimeout(() => {
                  this.tabParentIconMethod()
                  this.dataSearchStudent.idGrade = null
                  this.dataSearchStudent.idClass = null
                  this.loadingButton = false;
                }, 500);
              })
              .catch((err) => {
                this.$message({
                  message: err.data.message,
                  type: "error",
                });
              });
        }
      } else {
        let iconArrayTeacher = this.multipleSelectionTeacher1.concat(this.multipleSelectionTeacher2)
        if (iconArrayTeacher.length === 0) {
          this.$message({
            message: "Không có Icon App Teacher nào được chọn",
            type: "error",
          });
          this.loadingButton = false;
        } else {
          let dataUpdateTeacher = {
            idDepartment: this.idDepartment,
            appIconTeacherRequestList: iconArrayTeacher
          }
          EmployeeDataService.updateAppIconForManyTeacher(dataUpdateTeacher)
              .then((resp) => {
                this.$message({
                  message: resp.data.message,
                  type: resp.data.data === true ? "success" : "error",
                });
                setTimeout(() => {
                  this.tabTeacherIconMethod()
                  this.idDepartment = null
                  this.loadingButton = false;
                }, 500);
              })
              .catch((err) => {
                this.$message({
                  message: err.data.message,
                  type: "error",
                });
              });
        }
      }
    },
    tabParentIconMethod() {
      StudentService.findAllAppIconParentForSchool()
          .then((resp) => {
            this.parentIconAppUpdate.parentIconUpdate1 =
                resp.data.data.appIconResponseList1
            this.parentIconAppUpdate.parentIconUpdate2 =
                resp.data.data.appIconResponseList2
          })
          .catch((err) => {
            console.log(err);
          });
    },
    tabTeacherIconMethod() {
      EmployeeDataService.findAllAppIconTeacherForSchool()
          .then((resp) => {
            this.teacherIconAppUpdate.teacherIconUpdate1 =
                resp.data.data.appIconTeacherResponseList1
            this.teacherIconAppUpdate.teacherIconUpdate2 =
                resp.data.data.appIconTeacherResponseList2
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getDataInitial() {
      this.tabParentIconMethod();
      this.tabTeacherIconMethod()
    },

    /**
     * thay đổi khối
     */
    changeGradeMethod() {
      this.dataSearchStudent.idClass = null;
    },
    handleSelectionChangeParent1(val) {
      this.multipleSelectionParent1 = val;
    },
    handleSelectionChangeParent2(val) {
      this.multipleSelectionParent2 = val;
    },
    handleSelectionChangeTeacher1(val) {
      this.multipleSelectionTeacher1 = val;
    },
    handleSelectionChangeTeacher2(val) {
      this.multipleSelectionTeacher2 = val;
    },


  },
};
</script>
<style>
.hideUpload > div {
  display: none;
}
</style>
<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-dialog__body {
  padding-top: 0;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-button--primary {
  width: 100%;
  background: #409eff;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #606266;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #606266;
  background: #e7e9ec;
  border: 1px solid #dcdfe6;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
  margin-right: 50px;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 10px 0;
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

.el-select {
  display: block;
}

.table-icon-1 {
  width: 30%;
  margin-left: 15%;
  display: inline-block;
}

.table-icon-2 {
  width: 30%;
  margin-right: 15%;
  float: right;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.group-radio-status {
  margin: 0 50px;

  .kids-status {
    margin-bottom: 10px;
  }
}

.table-data {
  width: 60%;
  border-collapse: collapse;
  border: 1px solid #ebeef5;
  margin: 0 auto;
}

.wrapper-table {
  overflow-y: scroll;
  border-bottom: 1px solid #ebeef5;
}

.button-left-class {
  margin-right: 5px;
  width: 200px;
}

.button-click-left {
  margin-top: 5px;
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  position: relative;
  z-index: 1
}

.button-click-left .el-select {
  margin-right: 10px;
}

</style>
